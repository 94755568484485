import { CloseScreenshotIcon, SaveIcon, ShareIcon } from '@components/core/Icons'
import { Product } from '@customTypes/product'
import { Screenshot } from '@customTypes/screenshot'
import { useShare } from '@hooks/useShare'
import { trackClickSaveImg, useSharePhoto } from '@libs/analytics'
import { getNoCacheBrandUrl, saveScreenshot } from '@libs/saveScreenshot'
import { useConfig } from '@providers/configProvider'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ScreenshotImage } from './ScreenshotImage'
import {
  ScreenshotWrapper,
  CloseBtn,
  ProductInfo,
  BrandImage,
  ActionBox,
  ActionWrapper,
  ActionButton,
  ErrorMessage,
  ButtonWrapper,
} from './styles'
import { isDesktopView, isValidHttpUrl } from '@libs/utils'

interface ScreenshotPreviewProps {
  image?: Screenshot
  setImage: (img?: Screenshot) => void
  product: Product
}

export const ScreenshotPreview: React.FC<ScreenshotPreviewProps> = ({
  image,
  setImage,
  product,
}) => {
  const { styleName, frameColorLabel, lensColorLabel, brand } = product
  const { t } = useTranslation()
  const { store, style, onShare, disableSavePolaroid, polaroid } = useConfig()
  const { share, error, loading } = useShare(product, image)

  const trackSharePhoto = useSharePhoto(product)

  const onClose = () => {
    setImage()
  }

  const openLink = (isLinkA?: boolean) => {
    isLinkA
      ? isValidHttpUrl(polaroid?.linkA)
        ? window.open(polaroid?.linkA, '_blank', 'noreferrer')
        : // eslint-disable-next-line
          console.log('Not valid URL')
      : isValidHttpUrl(polaroid?.linkB)
      ? window.open(polaroid?.linkB, '_blank', 'noreferrer')
      : // eslint-disable-next-line
        console.log('Not valid URL')
  }

  const onSave = useCallback(() => {
    trackClickSaveImg()
    if (image) {
      saveScreenshot(image, product, style, store)
    }
  }, [image, product, style, store])

  const onShareUrl = useCallback(() => {
    if (loading) return
    trackSharePhoto()
    share()
  }, [share, trackSharePhoto, loading])

  return (
    <ScreenshotWrapper className="vmmv-screenshot-wrapper">
      <CloseBtn onClick={onClose}>
        <CloseScreenshotIcon width="1em" height="1em" fill="#000" stroke="#000" />
      </CloseBtn>
      <ScreenshotImage image={image} />
      <ActionWrapper>
        {onShare && !isDesktopView() && (
          <ActionBox onClick={onShareUrl} disabled={loading} position="start">
            {t('share.share')}
            <ShareIcon width="1em" height="1em" />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </ActionBox>
        )}
        {!disableSavePolaroid && (
          <ActionBox onClick={onSave} position="end" isDesktopView={isDesktopView()}>
            {t('share.save-image')}
            <SaveIcon width="1em" height="1em" />
          </ActionBox>
        )}
      </ActionWrapper>
      <BrandImage
        src={getNoCacheBrandUrl(style, brand, store.standAlone)}
        crossOrigin="anonymous"
      />
      {styleName && <ProductInfo>{styleName}</ProductInfo>}
      {frameColorLabel && <ProductInfo>{product.frameColorLabel}</ProductInfo>}
      {lensColorLabel && <ProductInfo>{product.lensColorLabel}</ProductInfo>}
      <ButtonWrapper>
        {polaroid?.linkA && (
          <ActionBox onClick={() => openLink(true)} disabled={loading} position="center">
            <ActionButton>{'Finde En Geschäft'}</ActionButton>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </ActionBox>
        )}
        {polaroid?.linkB && (
          <ActionBox onClick={() => openLink(false)} disabled={loading} position="center">
            <ActionButton>{'Finde En Geschäft'}</ActionButton>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </ActionBox>
        )}
      </ButtonWrapper>
    </ScreenshotWrapper>
  )
}
