import config from '@config/index'
import colorsData from '../colors.json'

import { ColorSwatches } from '@customTypes/product'

export const getColorSwatches = () => {
  // function currying
  return () =>
    new Promise<ColorSwatches[]>(resolve => {
      fetch(config.vmColorApiUrl + '/assets/transitions/colors.json')
        .then(res => res.json())
        .then(resolve)
        .catch(() => resolve(colorsData)) // we should return the local colors.json if the fetch would failed for fallback capability
    })
}
