import { Config } from './types/config'
import React, { Suspense } from 'react'
import { createRoot, Root } from 'react-dom/client'
import pkgVersion from './version'
import { VMWidgetQRCode } from './qrCodeWidget'
import { clearPictureVideoIds } from '@utils/sessionStorage'

import { CONTAINER_ID_VIDEO_MODE } from '@components/VideoMode/VideoModeView'
import { CONTAINER_ID_PICTURE_MODE } from '@components/PictureMode/PictureModeView'
import { CONTAINER_ID_LIVE_TRY_ON } from '@pages/LiveTryOn'
import { isBrowserSupported, isUPCSupported } from '@utils/helpers'

import(/* webpackPrefetch: true */ '@luxottica/frame-advisor-capture')
import(/* webpackPrefetch: true */ '@luxottica/vto-core')
import(/* webpackPrefetch: true */ '@luxottica/virtual-mirror')

const VMWidget = React.lazy(() => import('./VMWidget'))

interface IVMWidgetApp {
  config: Config
  init: () => void
  unmount: () => Promise<void>
  reset: () => Promise<void>
}

class VMWidgetApp implements IVMWidgetApp {
  public config: Config
  private root: Root | null
  constructor(config: Config) {
    this.config = config
    const container = document.querySelector(this.config.selector)
    this.root = container ? createRoot(container) : null
  }

  async init() {
    if (!this.config) {
      throw new Error('Config is not defined')
    }

    if (!this.root) {
      throw new Error(
        `You requested Virtual mirror to render inside the element with the selector ${this.config.selector}, but there is no such element in the document. Check the "selector" parameter in Virtual mirror initialization or your DOM.`
      )
    }

    this.root.render(
      <Suspense fallback={null}>
        <VMWidget config={this.config} />
      </Suspense>
    )
  }

  async unmount() {
    return new Promise<void>(async resolve => {
      if (this.root) {
        try {
          // use dynamic imports here
          const { store } = await import('./store/index')
          const { setVtoPage } = await import('@store/actions/ui')
          const { FrameAdvisorCapture } = await import('@luxottica/frame-advisor-capture')
          const { VirtualMirror } = await import('@luxottica/virtual-mirror')
          const { VtoCore } = await import('@luxottica/vto-core')

          const {
            ui: { vtoPage },
          } = store.getState()

          if (vtoPage === 'live-try-on') {
            await VirtualMirror.closeMirror({ target: CONTAINER_ID_LIVE_TRY_ON })
          } else if (vtoPage === 'picture-mode') {
            await (() =>
              new Promise<void>((resolve, reject) =>
                VtoCore.closeRender({ divId: CONTAINER_ID_PICTURE_MODE }, resolve, reject)
              ))()
          } else if (vtoPage === 'video-mode') {
            await (() =>
              new Promise<void>((resolve, reject) =>
                VtoCore.closeRender({ divId: CONTAINER_ID_VIDEO_MODE }, resolve, reject)
              ))()
          } else if (vtoPage === 'fa-vto-capture') {
            FrameAdvisorCapture.close()
            FrameAdvisorCapture.closeVtoCapture()
          } else if (vtoPage === 'fa-upload') {
            FrameAdvisorCapture.close()
            FrameAdvisorCapture.closeUploadImage()
          }

          store.dispatch(setVtoPage('off'))
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }

        this.root.unmount()
      }

      resolve()
    })
  }

  async reset() {
    if (this.root) {
      try {
        // use dynamic imports so it will not bundle this into the root JS
        const { FrameAdvisorCapture } = await import('@luxottica/frame-advisor-capture')
        const { VirtualMirror } = await import('@luxottica/virtual-mirror')

        VirtualMirror.resetBipaState()
        FrameAdvisorCapture.resetBipaState()
        clearPictureVideoIds()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
  }

  static new(config: Config) {
    return new VMWidgetApp(config)
  }

  static version() {
    return pkgVersion
  }
}

export { VMWidgetApp, VMWidgetQRCode, clearPictureVideoIds, isUPCSupported, isBrowserSupported }
