import { VirtualMirror, VirtualMirrorCatalogue } from '@luxottica/virtual-mirror'
import { useCallback, useEffect } from 'react'

import { Color, ColorSwatches } from '@customTypes/product'
import { useConfig } from '@providers/configProvider'
import { useRefValue } from '@hooks/useRefValue'
import { useVmInitParams } from '@hooks/useVmInitParams'
import { getColorSwatches } from '@libs/getColorSwatches'

interface VMInitializeProps {
  setIsReady: (value: boolean) => void
  setIsVMInitialized: (value: boolean) => void
  setColors: (colors: Color[]) => void
  children: React.ReactNode
}

export const VMInitialize: React.FC<VMInitializeProps> = ({
  children,
  setIsReady,
  setIsVMInitialized,
  setColors,
}) => {
  const config = useConfig()
  const vmInitParams = useVmInitParams(config, setIsReady)

  const initializeVirtualMirror = useCallback(
    async (colorSwatches: () => Promise<ColorSwatches[]>) => {
      try {
        await VirtualMirror.initialize(vmInitParams)
        setIsVMInitialized(true)

        const colorsData = await colorSwatches()

        VirtualMirrorCatalogue.build({
          ...((config.vmInit.glassesEnv && {
            catalogueEnvironment: config.vmInit.glassesEnv,
            // Use any due to impossible to import GlassesEnvironment type, required for env variable
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          }) as any),
          key: vmInitParams.options.key,
        })
          .downloadTransitionColorCatalogue()
          .then(transitionColors => {
            const colors: Color[] = Object.keys(transitionColors).map(colorCode => {
              const colorData = colorsData.find(color => color.colorCode === colorCode)
              return {
                colorCode,
                colorName: colorData?.colorName,
                lensName: colorData?.lensName,
                colorValue: colorData?.color,
              } as Color
            })
            setColors(colors)
          })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error initializing virtual mirror: ${error}`)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [vmInitParams, setIsVMInitialized, setColors, config.vmInit.glassesEnv]
  )
  const initVMRef = useRefValue(initializeVirtualMirror)

  useEffect(() => {
    initVMRef.current(getColorSwatches())
  }, [initVMRef])

  return <>{children}</>
}
