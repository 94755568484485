import { useCallback, useEffect } from 'react'

import { Color, ColorSwatches } from '@customTypes/product'
import { VtoCore, VtoCoreEnvironment } from '@luxottica/vto-core'
import { useConfig } from '@providers/configProvider'
import { useRefValue } from '@hooks/useRefValue'
import { useVtoInitParams } from '@hooks/useVtoInitParams'
import { getColorSwatches } from '@libs/getColorSwatches'

interface VtoInitializeProps {
  setIsVMInitialized: (value: boolean) => void
  setUserID: (value: string) => void
  setColors: (colors: Color[]) => void
  children: React.ReactNode
  onRefresh: () => void
}

const getGlassesEnvironment = (vtoEnv: VtoCoreEnvironment) =>
  vtoEnv.toLowerCase() === 'production' ? 'PROD' : 'TEST'

export const VtoInitialize: React.FC<VtoInitializeProps> = ({
  children,
  setIsVMInitialized,
  setUserID,
  setColors,
  onRefresh,
}) => {
  const config = useConfig()
  const { options } = useVtoInitParams(config, setUserID, onRefresh)

  const initializeVto = useCallback(
    async (colorSwatches: () => Promise<ColorSwatches[]>) => {
      try {
        // VtoCore.initialize(options, onSuccess)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
        VtoCore.initialize(options, ({ webglRendering }) => {})
        setIsVMInitialized(true)

        const colorsData = await colorSwatches()

        VtoCore.downloadTransitionColorCatalogue(
          // Use any due to impossible to import GlassesEnvironment type, required for env variable
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getGlassesEnvironment(options.environment) as any
        ).then(transitionColors => {
          const colors: Color[] = transitionColors.map(colorCode => {
            const colorData = colorsData?.find(
              (color: ColorSwatches) => color.colorCode === colorCode
            )
            return {
              colorCode,
              colorName: colorData?.colorName,
              lensName: colorData?.lensName,
              colorValue: colorData?.color,
            } as Color
          })
          setColors(colors)
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error initializing vto: ${error}`)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [options, setIsVMInitialized, setColors]
  )
  const initVMRef = useRefValue(initializeVto)

  useEffect(() => {
    initVMRef.current(getColorSwatches())
  }, [initVMRef])

  return <>{children}</>
}
