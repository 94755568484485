export const notUndefined = <T>(x: T | undefined): x is T => x !== undefined

export const isDesktopView = () => {
  return (
    window.innerWidth >= 1024 &&
    !/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    )
  )
}

export const isMobileView = () => {
  return window.innerWidth < 768
}

export const isSmallMobileView = () => {
  return window.innerWidth < 376
}

// eslint-disable-next-line
export const isValidHttpUrl = (url?: any) => {
  let validateUrl
  try {
    validateUrl = new URL(url)
  } catch (_) {
    return false
  }
  return validateUrl?.protocol === 'http:' || validateUrl?.protocol === 'https:'
}
