import { Resolution, UpcAvailability } from '@customTypes/upcAvailability'

export const isUPCSupported = async (
  upcs: string[],
  licenseKey: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  glassesEnv: any = 'PROD'
) => {
  const upcList = upcs.filter(x => !!x)

  const upcAvailability: UpcAvailability = {}

  // use dynamic import
  const { VirtualMirrorCatalogue } = await import('@luxottica/virtual-mirror')

  const catalogueUpcs = VirtualMirrorCatalogue.build({
    key: licenseKey,
    catalogueEnvironment: glassesEnv,
  }).isUpcSupported(...upcList)

  for (const upc of upcList) {
    const upcCatalog = await catalogueUpcs[upc]

    const getResolution = (resolution: { '256': boolean; '512': boolean }) => {
      return [
        resolution[256] ? 'PX_256' : undefined,
        resolution[512] ? 'PX_512' : undefined,
      ].filter(x => !!x) as Resolution[]
    }

    upcAvailability[upc] = {
      available: upcCatalog.isAvailable(),
      resolutions: getResolution(upcCatalog.available),
      transitions: upcCatalog.isTransition(),
      transitionsResolutions: getResolution(upcCatalog.transitions),
      lods: upcCatalog.lods,
    }
  }

  return upcAvailability
}

export const isBrowserSupported = async () => {
  const { VirtualMirror } = await import('@luxottica/virtual-mirror')

  return await VirtualMirror.isBrowserSupported()
}
