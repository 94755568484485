import { pxToEm } from '@libs/styled'
import styled from 'styled-components'
import { resetButtonStyles } from '@components/Menu/styles'
import { Button } from '@components/core/Button'

export const CloseBtn = styled.button`
  position: absolute;
  top: ${pxToEm(10)};
  right: ${pxToEm(10)};
  width: ${pxToEm(50)};
  height: ${pxToEm(50)};
  line-height: 0;

  ${resetButtonStyles}
`

export const ProductInfo = styled.p`
  color: ${({ theme }) => theme.palette.black};
  font: ${({ theme }) => theme.fonts.span};
  line-height: 1.25;

  ${({ theme }) => theme.media.medium} {
    font: ${({ theme }) => theme.fonts.paragraph};
  }
`

export const BrandImage = styled.img`
  width: ${pxToEm(120)};
  height: auto;
  margin: ${pxToEm(24)} 0 ${pxToEm(10)};
  max-height: ${pxToEm(30)};

  ${({ theme }) => theme.media.medium} {
    width: ${pxToEm(240)};
    max-height: ${pxToEm(60)};
  }
`

export const ActionBox = styled.div<{
  disabled?: boolean
  position?: string
  isDesktopView?: boolean
}>`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: ${({ isDesktopView, position }) => (isDesktopView ? 'center' : position)};
  align-items: center;
  padding: ${pxToEm(5)} 0;
  margin: 0 auto;
  white-space: nowrap;
  color: ${({ theme, disabled }) => (disabled ? theme.palette.darkGray : theme.palette.black)};
  font: ${({ theme }) => theme.fonts.span};

  ${({ theme }) => theme.media.medium} {
    font: ${({ theme }) => theme.fonts.paragraph};
  }
  svg {
    margin: 0 ${pxToEm(10)};
  }
`
export const ActionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 70%;
  justify-content: space-between;
  align-items: center;
  /* padding: ${pxToEm(16)} 0; */
  font: ${({ theme }) => theme.fonts.span};
`
export const ButtonWrapper = styled.div`
  position: static;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  justify-content: space-evenly;
  margin: ${pxToEm(10)} 0;
  align-items: center;
  font: ${({ theme }) => theme.fonts.span};
`

export const ActionButton = styled(Button)`
  background: black;
  padding: ${pxToEm(17)} ${pxToEm(16)};
`

export const ErrorMessage = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: ${({ theme }) => theme.palette.pinkRed};
  font: ${({ theme }) => theme.fonts.span};
`

export const ScreenshotWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${pxToEm(30)} 10%;
  overflow: auto;

  ${({ theme }) => theme.media.medium} {
    padding: ${pxToEm(80)} 10%;
  }

  ${({ theme }) => theme.media.large} {
    padding: ${pxToEm(80)} ${pxToEm(165)};
  }

  ${ProductInfo} {
    margin-bottom: ${pxToEm(2)};
  }
`
